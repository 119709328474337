import React from 'react'

import Layout from '../../components/layout'
import CheckoutPayment from '../../components/CheckoutPayment'

class CheckoutPaymentPage extends React.Component {
  state = {
    initialized: false
  }

  componentDidMount() {
    this.setState({ initialized: true })
  }

  render() {
    const { initialized } = this.state

    return (
      <Layout>
        <h1 className="h2">checkout payment page</h1>

        <div className="row">
          <div className="col-12">

            {initialized ? (
              <CheckoutPayment />
            ) : (
              <></>
            )}

          </div>
        </div>

      </Layout>
    )
  }
}

export default CheckoutPaymentPage
