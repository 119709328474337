import React from 'react';
// import { navigate } from '@reach/router';
import { Link, navigate } from 'gatsby';

import CartContext from '../Context/CartContext'
import LoadingIndicator from '../loadingindicator'

import { getOrder } from '../../lib/api/order'

class CheckoutPayment extends React.Component {
  context = null

  state = {
    loading: false,
    attempt: 0,
    error: false
  }

  checkOrder() {
    if (this.context.transactionid) {
      getOrder(this.context.transactionid).then(result => {
        if (result) {
          if (result.action) {
            if (result.action === 'order-confirmation' && result.order.id) {
              navigate(`/order/thanks?id=${result.order.cartid}`)
            }
            if (result.action === 'checkout-overview') {
              navigate(`/checkout/overview`)
            }
          }
          if (+this.state.attempt <= 15) {
            this.setState({ error: false, attempt: +this.state.attempt + 1 }, () => {
              setTimeout(() => { this.checkOrder() }, 3000)
            })
          } else {
            this.setState({ error: true })
          }
        }
      })
    }
  }

  componentDidMount() {
    this.checkOrder()
  }

  render() {
    const { loading, error } = this.state

    return (
      <CartContext.Consumer>
        {context => {
          this.context = context
          return (
            <>
              {context && context.cart && (

                <LoadingIndicator loading={loading}>
                  {error ? (
                    <div className="alert alert-warning">
                      <p className="m-0">Het spijt ons maar we krijgen geen antwoord.</p>
                      <p className="m-0">
                        <Link to={`/checkout/overview`} className="btn btn-primary">Nog eens proberen</Link>
                      </p>
                    </div>
                  ) : (
                    <div className="alert alert-info">
                      <p className="m-0">Even geduld ... Wij wachten op de bevestiging van uw betaling.</p>
                    </div>
                  )}

                </LoadingIndicator>

              )}
            </>
          )
        }}
      </CartContext.Consumer>
    )
  }
}

export default CheckoutPayment
